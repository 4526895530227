import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";

import Layout from "../Components/Layout/Layout";

export default function Dashboard() {
  return (
    <Layout>
      <Container fluid>
        <div className="dashboard-page">
          <div className="edit-profile-page">
            <div className="dash-image">
              <img src={require("../Assets/Images/dashbord-upcoming.png")} />
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
