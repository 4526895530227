import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Pages/Login";
import SignUp from "./Pages/SignUp";
import OtpVarification from "./Pages/OtpVarification";
import UploadDocuments from "./Pages/UploadDocuments";
import EditProfile from "./Pages/EditProfile";
import ChangePassword from "./Pages/ChangePassword";
import BankDetails from "./Pages/BankDetails";
import ProfileCreated from "./Pages/ProfileCreated";
import Dashboard from "./Pages/Dashboard";
import { ProfileProvider } from "./Context/ProfileContext";
import ProtectedRoutes from "./Components/Layout/ProtectedRoutes";

function App() {
  return (
    <div className="App test">
      <BrowserRouter>
          <ProfileProvider>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/sign-up" element={<SignUp />}></Route>
          <Route
            path="/otp-varification/:id"
            element={<OtpVarification />}
          ></Route>
          
          <Route path="/upload-documents" element={<UploadDocuments />}></Route>
          <Route path="/bank-details" element={<BankDetails />}></Route>
          <Route element={<ProtectedRoutes/>}>
          
          <Route path="/edit-profile" element={<EditProfile />}></Route>
          <Route path="/change-password" element={<ChangePassword />}></Route>
          <Route path="/profile-created" element={<ProfileCreated />}></Route>
          <Route path="/dashboard" element={<Dashboard />}></Route>
            
          </Route>
        </Routes>
          </ProfileProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
